import React, { useState } from "react";
import { Layout, Row, Col } from "antd";
import { Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom"; // Ensure you have `react-router-dom` installed
import { HeartFilled } from "@ant-design/icons";
import FacebookIcon from "../../assets/images/facebook.svg";
import InstagramIcon from "../../assets/images/instagram.svg";
import TwitterIcon from "../../assets/images/twitter1.png";
import PinterestIcon from "../../assets/images/pinterest.svg";
import PhoneIcon from "../../assets/images/phone.svg";
import EmailIcon from "../../assets/images/mail.svg";
import LoginModal from "../../components/LoginModal";

function Footer() {
  const { Footer: AntFooter } = Layout;
  const [login, setLogin] = useState(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    const isLoggedIn = localStorage.getItem("token");
    if (!isLoggedIn) {
      setLogin(true);
    } else {
    }
  };

  const buttonStyle = {
    background: "none",
    border: "none",
    padding: 0,
    margin: 0,
    cursor: "pointer",
    color: "inherit",
    fontSize: "inherit",
    // textDecoration: "underline",
  };

  const handleMyAccount = (id) => {
    const isLoggedIn = localStorage.getItem("token");
    if (!isLoggedIn) {
      setLogin(true);
    } else {
      navigate("/account");
    }
  };

  return (
    <>
      <AntFooter className="ant-layout-footer">
        <Container>
          <Row>
            <Col span={24} md={6}>
              <div className="footer-main">
                <h4>Information</h4>
                <ul>
                  <li>
                    <Link to="/policies">
                      Cancellation/Refund Policies
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      Privacy policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">
                      Terms & Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col span={24} md={6}>
              <div className="footer-main">
                <h4>ORDER</h4>
                <ul>
                  <li>
                    <button style={buttonStyle} onClick={handleMyAccount}>
                      My Account
                    </button>
                  </li>
                  {/* <li>
                    <button style={buttonStyle} onClick={handleLogin}>
                      Log in
                    </button>
                  </li> */}
                </ul>
              </div>
            </Col>
            <Col span={24} md={6}>
              <div className="footer-main">
                <h4>FOLLOW US</h4>
                <ul>
                  <li>
                    <Link to="https://www.facebook.com/?locale2=en_GB&_rdr">
                      <img src={FacebookIcon} alt="Facebook" />
                      Facebook
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.instagram.com/jewellskart4/profilecard/?igsh=NnJwa2NrajRrZzR3">
                      <img src={InstagramIcon} alt="Instagram" />
                      Instagram
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.pinterest.com/jewellskart4/?actingBusinessId=953426321021851632">
                      <img src={PinterestIcon} alt="Pinterest" />
                      Pinterest
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.twitter.com">
                      <img src={TwitterIcon} alt="Twitter" />
                      Twitter
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col span={24} md={6}>
              <div className="footer-main">
                <h4>Contact Us</h4>
                <ul>
                  <li>
                    <a href="mailto:support@jewellskart.com">
                      <img src={EmailIcon} alt="Email" />
                      support@jewellskart.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:+919204824823">
                      <img src={PhoneIcon} alt="Phone" />
                      +91-9204824823
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </AntFooter>
      <div className="mini-footer">
        <p>Copyright 2024, All Rights Reserved By AYATI JEWELLS LLP</p>
      </div>

      <LoginModal open={login} setOpen={setLogin} />
    </>
  );
}

export default Footer;
