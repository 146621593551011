import { Button, Row, Col, Form, Input, Modal, Typography } from "antd";

import React, { useContext, useEffect, useState, Suspense, lazy } from "react";

import { Container } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel3";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import { AuthContext } from "../context/AuthContext";
import Carousel from "react-bootstrap/Carousel";
import BANNER01 from "../assets/images/BANNER 1.jpg";
import BANNER02 from "../assets/images/BANNER 2.jpg";
import BANNER03 from "../assets/images/BANNER 3.jpg";
import GetMade from "../assets/images/jewellskart-7.jpg";
import WhyToUse from "../assets/images/jewellskart-10.jpg";
import Advertise from "../assets/images/Advertise.jpg";
import Product01 from "../assets/images/ring-icon.png";
import { useNavigate } from "react-router";
import PhoneInput from "react-phone-input-2";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import ImagePlaceholder from "../assets/images/ImagePlaceholder.png";
const Footer = lazy(() => import("../components/layout/Footer"));
const LoginModal = lazy(() => import("../components/LoginModal"));
const GuestInfo = lazy(() => import("../components/GuestInfo"));
const UploadModal = lazy(() => import("../pages/UploadModal"));

const HomePage = (open, setOpen) => {
  const token = localStorage.getItem("token");
  const isLoggedIn = localStorage.getItem("token");
  const [visible01, setVisible01] = useState(false);
  const navigate = useNavigate();
  const [loginModal, setLoginModal] = useState(false);
  const [guestModal, setGuestModal] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [mainCategoryData, setMainCategoryData] = useState([]);
  const [mainJewls, setMainJewls] = useState([]);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const { setCartItemCount, setWishItemCount } = useContext(AuthContext);
  const { setUserProfile } = useContext(AuthContext);

  const { Title } = Typography;

  // const showView = (id) => {
  //   const userProfile = localStorage.getItem("userProfile")
  //     ? JSON.parse(localStorage.getItem("userProfile"))
  //     : null;
  //   console.log(userProfile);
  //   if (
  //     userProfile.vType === "guest" &&
  //     userProfile.mobile_number === "1234567890"
  //   ) {
  //     setGuestModal(true);
  //   } else {
  //     navigator(`/product-detail/${id}`);
  //   }
  // };

  useEffect(() => {
    setLoading(true);
    request({
      url: "/common/main",
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data, "alfjloashfdk");
        setLoading(false);
        // ShowToast(data.message, Severty.SUCCESS)
        setCategoryData(data.data?.menuCtgWise || []);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    request({
      url: "/common/categoryWiseData",
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data);
        setLoading(false);
        // ShowToast(data.message, Severty.SUCCESS)
        setMainCategoryData(data.data.menuCtgWise);
        setMainJewls(data.data.TopSneakers);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, []);
  // useEffect(() => {
  //   if (token) {
  //     setLoading(true);
  //     request({
  //       url: "/common/getProduct",
  //       method: "GET",
  //       onSuccess: (data) => {
  //         console.log(data.data);
  //         setLoading(false);
  //         // ShowToast(data.message, Severty.SUCCESS)
  //         setProductData(data.data.data);
  //       },
  //       onError: (error) => {
  //         console.log(error);
  //         ShowToast(error, Severty.ERROR);
  //       },
  //     });
  //   }
  // }, [token]);

  useEffect(() => {
    setLoading(true);
    request({
      url: "/common/getProduct",
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data);
        setLoading(false);
        // ShowToast(data.message, Severty.SUCCESS)
        setProductData(data.data.data);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, []);

  const onSubmit = (values) => {
    console.log("Received values of form: ", values);
    // Perform your API call or other submission logic here
    request({
      url: "/common/contact-us",
      method: "POST",
      data: values,
      onSuccess: (data) => {
        ShowToast(data.message, Severty.SUCCESS);
        form.resetFields(); // Clear the form after successful submission
      },
      onError: (error) => {
        ShowToast(error.message, Severty.ERROR);
      },
    });
  };

  const addWishList = (id) => {
    const isLoggedIn = localStorage.getItem("token");
    if (!isLoggedIn) {
      setLoginModal(() => true);
    } else {
      request({
        url: `/shopping/favorite?productId=${id}`,
        method: `GET`,
        onSuccess: (data) => {
          setLoading(false);
          if (data.status) {
            ShowToast(data.message, Severty.SUCCESS);
            setProductData((prev) =>
              prev.map((item) => {
                if (item.id === id) {
                  setWishItemCount((prev) =>
                    !item.isFavorite ? prev + 1 : prev - 1
                  );
                  return { ...item, isFavorite: !item.isFavorite };
                }
                return item;
              })
            );
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          ShowToast(error.response.data.message, Severty.ERROR);

          setLoading(false);
        },
      });
    }
  };
  const addToCart = (id) => {
    const isLoggedIn = localStorage.getItem("token");
    if (!isLoggedIn) {
      setLoginModal(() => true);
    } else {
      request({
        url: `/shopping/cart?productId=${id}`,
        method: `POST`,
        onSuccess: (data) => {
          setLoading(false);
          if (data.status) {
            setProductData((prev) =>
              prev.map((item) => {
                if (item.id === id) {
                  setCartItemCount((prev) =>
                    !item.addInCard ? prev + 1 : prev - 1
                  );
                  return { ...item, addInCard: !item.addInCard };
                }
                return item;
              })
            );
            ShowToast(data.message, Severty.SUCCESS);
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          ShowToast(error.response.data.message, Severty.ERROR);

          setLoading(false);
        },
      });
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    designation: "",
    store: "",
    address: "",
    email: "",
    gst: "",
    password: "",
  });

  const handleEyeClick = (id) => {
    const isLoggedIn = localStorage.getItem("token");

    if (!isLoggedIn || isLoggedIn == null) {
      setIsModalVisible(true);
    } else {
      // navigate(`/product-detail/${id}`, "_blank");
      window.open(`/product-detail/${id}`, "_blank");
    }
  };

  const [form] = Form.useForm();

  const [mobileNumber, setMobileNumber] = useState({
    mobile: "",
    country_code: "",
  });

  const handleSubmit = (values, id) => {
    const { email, password, name, gst, address, store, designation } = values;

    if (!email)
      return ShowToast("Please enter email to sign in", Severty.ERROR);

    const payload = { password };
    if (!email) return ShowToast("Please enter valid email ", Severty.ERROR);

    // setLoading(true);
    payload.email = email;
    payload.password = password;
    payload.mobile_number = mobileNumber.mobile;
    payload.country_code = mobileNumber.country_code;
    payload.name = name;
    payload.gst = gst;
    payload.address = address;
    payload.store = store;
    payload.designation = designation;
    payload.otp = "1234";
    request({
      url: "https://jewellskart.com/apanel/api/signUp_new",
      // url: "/auth/signUp",
      // url: "/modal/user",
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        // setLoading(false);
        console.log(data, "data.message");
        if (data.status) {
          console.log(data);
          handleClose();
          localStorage.setItem("token", data.api_token);
          localStorage.setItem("userProfile", JSON.stringify(data.data));
          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile(data.data);
          window.location.reload();
        } else {
          ShowToast(data.errors, Severty.ERROR);
          // navigate(`/product-detail/${id}`);
        }
      },
      onError: (error) => {
        // ShowToast(error.response.data.message, Severty.ERROR);
        // setLoading(false);
      },
    });
  };

  const handleChangeMobile = (value, data, event, formattedValue) => {
    var country_code = data.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile: value.slice(data.dialCode.length),
    });
  };

  const handleClose = () => {
    // setOpen(false);
    form.resetFields();
    setMobileNumber({
      mobile: "",
      country_code: "",
    });
  };
  const validateGST = (rule, value) => {
    // GST regex for basic format check
    const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[A-Z\d]{1}$/;

    if (!value) {
      return Promise.reject("Please enter your GST number!");
    }

    if (!gstRegex.test(value)) {
      return Promise.reject("Invalid GST number format");
    }

    // Add more complex validation logic here if needed, such as checksum verification

    return Promise.resolve();
  };
  const handleError = (e) => {
    e.target.src = ImagePlaceholder;
  };
  return (
    <>
      <Carousel className="main-owl-size-image">
        <Carousel.Item
          interval={3000}
          pause="hover"
          className="custom-carousel"
        >
          <LazyLoadImage
            onError={handleError}
            effect="blur"
            src={BANNER01}
            className="d-block w-100"
            alt="..."
          />
        </Carousel.Item>
        <Carousel.Item className="custom-carousel">
          <LazyLoadImage
            onError={handleError}
            effect="blur"
            src={BANNER02}
            className="d-block w-100"
            alt="..."
          />
        </Carousel.Item>
        <Carousel.Item className="custom-carousel">
          <LazyLoadImage
            onError={handleError}
            effect="blur"
            src={BANNER03}
            className="d-block w-100"
            alt="..."
          />
        </Carousel.Item>

        <Carousel.Item className="custom-carousel">
          <LazyLoadImage
            onError={handleError}
            effect="blur"
            src={GetMade}
            className="d-block w-100"
            alt="..."
          />
        </Carousel.Item>
      </Carousel>

      <div className="category-section">
        <div className="headding">
          <h4>Silver Jewellery by category</h4>
          <div className="bar"></div>
        </div>
        <Container>
          <Row gutter={24} className="container-flex justify-content-center">
            {categoryData.map((item) => (
              <Col key={item.categoryId} span={24} sm={8} md={8} lg={6}>
                <Link
                  to={`/product/category/${item.categoryId}`}
                >
                  <div className="item">
                    <div className="category-items second-catge">
                      <a className="product-z">
                        <LazyLoadImage
                          onError={handleError}
                          effect="blur"
                          src={`https://jewellskart.com/apanel/public/upload/${item.image}`}
                          alt={item.product_categorys}
                        />
                      </a>
                      <h4>{item.product_categorys}</h4>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className="why-to-use">
        <LazyLoadImage onError={handleError} effect="blur" src={WhyToUse} />
      </div>
      {/* 
      <div className="category-ex-section">
        <Container>
          <Row>
            <Col span={24} md={12}>
              <div className="sl-detail-main">
                <h4>
                  Silver <br />
                  Dimonds Earrings
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
                  integer lorem amet arcu egestas congue. Rhoncus scelerisque m
                  aenean ac. Cursus in at sagittis vivamus Rhoncus scelerisque m
                  aenean{" "}
                </p>
                <Button type="primary" className="slaider-btn">
                  Explore More
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      <div className="category-section">
        <div className="headding">
          <h4>Silver Collection</h4>
          <div className="bar"></div>
        </div>
        <Container>
          <Row gutter={24} className="container-flex justify-content-center">
            {mainJewls.map((item) => (
              <Col key={item.categoryId} span={24} sm={8} md={8} lg={6}>
                <div className="item">
                  <div className="category-items second-catge">
                    {/* Removed the <a> tag wrapping the <LazyLoadImage  onError={handleError} effect="blur"> */}
                    <Link
                      className="product-z"
                      to={`/product/category/${item.id}`}
                    >
                      <LazyLoadImage
                        onError={handleError}
                        effect="blur"
                        src={`https://jewellskart.com/apanel/public/upload/${item.image}`}
                        alt={item.product_categorys}
                      />
                    </Link>
                    <br></br>
                    <h5>{item.name}</h5>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      {/* <div className="collection-section">
        <div className="headding">
          <h4>Silver Collection </h4>
          <div className="bar"></div>
        </div>
        <Container>
          <Row>
            <Col span={24}>
              <OwlCarousel
                className="owl-theme collection-owl"
                items={1}
                loop
                margin={26}
                nav={false}
                dots={true}
              >
                {mainJewls.length > 0 &&
                  mainJewls.map((data) => (
                    <div className="item">
                      <a
                        href={`/product/jewelly/${data.id}`}
                      >
                        <div className="category-items-list">
                          <div className="collection-items">
                            <LazyLoadImage  onError={handleError} effect="blur"
                              src={`https://jewellskart.com/apanel/public/upload/${data.image}`}
                              height={""}
                              width={""}
                            />
                            <h4>{data.name}</h4>
                          </div>

                          <div className="collection-center-list">
                            <div className="collection-items">
                              <LazyLoadImage  onError={handleError} effect="blur"
                                src={`https://jewellskart.com/apanel/public/upload/${data.image}`}
                                height={""}
                                width={""}
                              />
                              <h4>{data.name}</h4>
                            </div>
                            <div className="collection-inner-list">
                              <div className="collection-items">
                                <LazyLoadImage  onError={handleError} effect="blur"
                                  src={`https://jewellskart.com/apanel/public/upload/${data.image}`}
                                  height={""}
                                  width={""}
                                />
                                <h4>{data.name}</h4>
                              </div>
                              <div className="collection-items">
                                <LazyLoadImage  onError={handleError} effect="blur"
                                  src={`https://jewellskart.com/apanel/public/upload/${data.image}`}
                                  height={""}
                                  width={""}
                                />
                                <h4>{data.name}</h4>
                              </div>
                            </div>
                          </div>
                          <div className="collection-items last-collections">
                            <LazyLoadImage  onError={handleError} effect="blur"
                              src={`https://jewellskart.com/apanel/public/upload/${data.image}`}
                              height={""}
                              width={""}
                            />
                            <h4>{data.name}</h4>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </div> */}
      <div className="headding">
        <h4>How it's work</h4>
        <div className="bar"></div>
      </div>
      <div className="how-it-work">
        <div className="get-to-use">
          <LazyLoadImage onError={handleError} effect="blur" src={Advertise} />
        </div>
      </div>
      <div className="trending-section">
        <div className="headding">
          <h4>TRENDING PRODUCT</h4>
          <div className="bar"></div>
        </div>
        <Container>
          <Row>
            <Col span={24}>
              <OwlCarousel
                className="owl-theme  category-section"
                items={5}
                margin={26}
                nav
                responsive={{
                  0: {
                    items: 1,
                  },
                  400: {
                    items: 2,
                  },
                  600: {
                    items: 3,
                  },
                  1000: {
                    items: 3,
                  },
                  1200: {
                    items: 4,
                  },
                  1400: {
                    items: 5,
                  },
                }}
                dots={false}
              >
                {productData.length > 0 &&
                  productData.map((data) => (
                    <div
                      className="item"
                      key={data.id}
                      onClick={() => handleEyeClick(data.id)}
                    >
                      <div className="products-items-list">
                        <div className="pruducts-items">
                          {data.product_image ? (
                            <LazyLoadImage
                              onError={handleError}
                              effect="blur"
                              src={`https://jewellskart.com/apanel/public/upload/product_image/${data.product_image.split(",")[0]}`}
                              alt="img"
                            />
                          ) : (
                            <LazyLoadImage
                              onError={handleError}
                              effect="blur"
                              src={Product01}
                              alt="default product"
                            />
                          )}
                          <ul className="d-flex align-items-center justify-content-center list-unstyled icons">
                            <li
                              className="icon"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent parent click handler from firing
                                handleEyeClick(data.id); // You can also move this to the specific icon if needed
                              }}
                            >
                              <span className="fas fa-eye"></span>
                            </li>
                            <li
                              className="icon mx-3"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent parent click handler from firing
                                addWishList(data.id);
                              }}
                            >
                              {data.isFavorite ? (
                                <span className="fa fa-heart"></span>
                              ) : (
                                <span className="far fa-heart"></span>
                              )}
                            </li>
                            <li
                              className="icon"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent parent click handler from firing
                                addToCart(data.id);
                              }}
                            >
                              <i
                                className="fa fa-shopping-bag"
                                aria-hidden="true"
                              ></i>
                            </li>
                          </ul>
                        </div>
                        <div className="product-detail">
                          <h6>{data.product_name}</h6>
                          <div className="ratting-star">
                            <span>
                              <StarFilled />
                            </span>
                            <span>
                              <StarFilled />
                            </span>
                            <span>
                              <StarFilled />
                            </span>
                            <span>
                              <StarFilled />
                            </span>
                            <span>
                              <StarOutlined />
                            </span>
                          </div>

                          {isLoggedIn ? (
                            data.discount > 0 ? (
                              <p className="price-ff">
                                <span>Rs {data.total_price}</span> RS {data.offer_price}
                              </p>
                            ) : (
                              <p className="price-ff">{data.total_price}</p>
                            )
                          ) : (
                            <p className="price-ff">
                              <span>Please log in to see the price</span>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="get-to-use">
        <LazyLoadImage
          onError={handleError}
          effect="blur"
          src={GetMade}
          onClick={() => setVisible01(true)}
        />
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <Footer />
      </Suspense>
      <Modal
        className="sign-modal"
        open={isModalVisible}
        footer={null}
        onOk={() => handleSubmit(formData)}
        onCancel={() => setIsModalVisible(false)}
      >
        <Row justify="space-around">
          <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
            <div className="signup-form">
              <div className="modal-title">
                <Title className="mb-15" style={{ textAlign: "center" }}>
                  Save Your Data
                </Title>
              </div>
              <Form
                form={form}
                layout="vertical"
                className="row-col"
                onFinish={handleSubmit}
              >
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        { required: true, message: "Please enter your name!" },
                      ]}
                    >
                      <Input className="sign-innn" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Designation"
                      name="designation"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your designation!",
                        },
                      ]}
                    >
                      <Input className="sign-innn" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="GST No"
                      name="gst"
                      rules={[
                        {
                          required: true,
                          // message: "GST number is required",
                          validator: validateGST,
                        },
                      ]}
                    >
                      <Input className="sign-innn" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Store Name"
                      name="store"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your store name!",
                        },
                      ]}
                    >
                      <Input className="sign-innn" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Address"
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your address!",
                        },
                      ]}
                    >
                      <Input className="sign-innn" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Email Address"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "Please enter a valid email address!",
                        },
                        { required: true, message: "Please enter your email!" },
                      ]}
                    >
                      <Input className="sign-innn" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Mobile Number">
                      <PhoneInput
                        className="username"
                        inputProps={{
                          name: "mobile",
                          required: true,
                          autoFocus: false,
                        }}
                        isValid={(value, country) => {
                          if (value.match(/1234/)) {
                            return (
                              "Invalid value: " + value + ", " + country.name
                            );
                          } else if (value.match(/1234/)) {
                            return (
                              "Invalid value: " + value + ", " + country.name
                            );
                          } else {
                            return true;
                          }
                        }}
                        country={"kw"}
                        value={
                          mobileNumber
                            ? (mobileNumber.country_code
                              ? mobileNumber.country_code
                              : "+91") +
                            (mobileNumber.mobile ? mobileNumber.mobile : null)
                            : ""
                        }
                        onChange={handleChangeMobile}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        className="sign-innn"
                        autoComplete="off"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button className="sign-btn" type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Modal>
      <Suspense fallback={<div>Loading...</div>}>
        <LoginModal open={loginModal} setOpen={setLoginModal} />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <GuestInfo open={guestModal} setOpen={setGuestModal} />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <UploadModal open={visible01} setOpen={setVisible01} />
      </Suspense>
      {/* <Footer>
      {/* <Container>
        <Row>
          <Col span={12} md={6}>
            <div className="footer-main">
              <h4>Information</h4>
              <ul>
                <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a href="#">Offers Details</a>
                </li>
                <li>
                  <a href="#">Privacy policy</a>
                </li>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">terms and conditions</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col span={12} md={6}>
            <div className="footer-main">
              <h4>ORDER</h4> <Carousel.Item>
          <LazyLoadImage src={BANNER04} className="d-block w-100" alt="..." />
        </Carousel.Item>
              <ul>
                <li>
                  <a href="#">My Account</a>
                </li>
                <li>
                  <a href="#">Log in</a>
                </li>
                <li>
                  <a href="#">My Addresses</a>
                </li>
                <li>
                  <a href="#">My Orders</a>
                </li>
              </ul>span={24}
            </div>
          </Col>
          <Col span={12} md={6}>
            <div className="footer-main">
              <h4>FOLLOW US</h4>
              <ul>
                <li>
                  <a href="#"><LazyLoadImage src={FacebookIcon} />Facebook</a>
                </li>
                <li>
                  <a href="#"><LazyLoadImage src={InstagramIcon} />Instagram</a>
                </li>
                <li>
                  <a href="#"><LazyLoadImage src={PinterestIcon} />Pinterest</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col span={12} md={6}>
            <div className="footer-main">
              <h4>Contact Us</h4>
              <ul>
                <li>
                  <a href="#"><LazyLoadImage src={FacebookIcon} />Mail to Us</a>
                </li>
                <li>
                  <a href="#"><LazyLoadImage src={InstagramIcon} />+91-9204824823</a>
                </li>
                <li>
                  <a href="#"><LazyLoadImage src={PinterestIcon} />Chat to Us</a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container> */}
      {/* </Footer> */}
      {/* <div className="mini-footer">
        <p>@ 2023 Jewelery  company limited. All right reserved. </p>
      </div> */}
    </>
  );
};

export default HomePage;
