import { Button, Row, Col } from "antd";

import React, { useContext, useEffect, useState } from "react";

import { Container } from "react-bootstrap";

import { CloseOutlined } from "@ant-design/icons";

import User01 from "../assets/images/wish-list.jpg";

import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";
import moment from "moment";
import { AuthContext } from "../context/AuthContext";

const Wishlist = (initialWishlistData) => {
  const [wishlistData, setWishlistData] = useState(initialWishlistData);
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const { setCartItemCount, setWishItemCount } = useContext(AuthContext);
  const [login, setLogin] = useState();
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    setLoading(true);
    request({
      url: "/shopping/favorite-list",
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data.data);
        setLoading(false);
        setWishlistData(data.data.data);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, []);

  const handleRemoveItem = (id) => {
    removeWishList(id); // Assuming removeWishList function handles the removal logic
    setWishlistData((prev) => prev.filter((item) => item.id !== id)); // Update local state
    setWishItemCount((prev) => prev - 1); // Decrease wishItemCount
  };

  const removeWishList = (id) => {
    console.log(id);
    const isLoggedIn = localStorage.getItem("token");
    if (!isLoggedIn) {
      setLogin(() => true);
    } else {
      // setLoading(true);

      request({
        url: `/shopping/favorite?productId=${id}`,
        method: `GET`,
        onSuccess: (data) => {
          // setLoading(false);
          if (data.status) {
            ShowToast(data.message, Severty.SUCCESS);
            setProductData((prev) =>
              prev.map((item) =>
                item.id === id ? { ...item, isFavorite: false } : item
              )
            );
            // Decrease wishItemCount only if the item was favorited before
            setWishItemCount((prev) => (prev > 0 ? prev - 1 : 0));
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          ShowToast(error.response.data.message, Severty.ERROR);
          setLoading(false);
        },
      });
    }
  };

  const addToCart = (id) => {
    const isLoggedIn = localStorage.getItem("token");
    if (!isLoggedIn) {
      setLogin(() => true);
    } else {
      request({
        url: `/shopping/cart?productId=${id}`,
        method: "POST",
        onSuccess: (data) => {
          if (data.status) {
            setCartItemCount((prev) => prev + 1);
            ShowToast(data.message, Severty.SUCCESS);
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          ShowToast(error.response.data.message, Severty.ERROR);
        },
      });
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col span={24} md={24}>
            <div className="headding-main">
              <h4>Wishlist</h4>
            </div>
            <div className="main-list-wishing">
              <ul>
                {wishlistData.length > 0 &&
                  wishlistData.map((item) => (
                    <li key={item.id}>
                      <div className="wishlist">
                        <div className="user-icon">

                          <img src={`https://jewellskart.com/apanel/public/upload/product_image/${item.product_image.split(",")[0]}`} alt="User" />
                        </div>
                        <div className="wishlist-headding">
                          <h6>{item.product_name}</h6>
                          <p className="price-spin">Rs. {item.total_price}</p>
                          <p>
                            {moment(item.created_at).format("MMMM DD,YYYY")}{" "}
                            <span className="time-text">
                              {moment(item.created_at).format("hh:mm A")}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="right-list-wish">
                        <p>In Stock</p>
                        <Button onClick={() => addToCart(item.id)}>
                          Add to cart
                        </Button>
                      </div>
                      <div className="close-errow">
                        <Button
                          className="close-btn"
                          onClick={() => handleRemoveItem(item.id)}
                        >
                          <CloseOutlined />
                        </Button>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Wishlist;
